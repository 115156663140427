import React, { Component } from 'react';

// vendor components
import Helmet from 'react-helmet';

const termlyTOSId = process.env.GATSBY_TERMLY_TOS_ID;

class TermsAndConditions extends Component {
  render() {
    const iframeSrc = `https://app.termly.io/document/terms-of-use-for-website/${termlyTOSId}`;

    return (
      <>
        <iframe
          width="100%"
          style={{ height: '95vh' }}
          title="Terms and Conditions"
          src={iframeSrc}
          frameBorder="0"
          allowFullScreen
        >
          <p>Your browser does not support iframes.</p>
        </iframe>
        <Helmet title="Terms & Conditions" />
      </>
    );
  }
}

export default TermsAndConditions;
